import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "stepper-progress" }
const _hoisted_2 = { class: "stepper-progress-bar" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 2,
  class: "stepper-number"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "stepper-container" }
const _hoisted_7 = { class: "stepper-content" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "stepper-controls" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["disabled"]
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["type"]
const _hoisted_15 = { class: "stepper-controls" }
const _hoisted_16 = {
  class: "stepper-button",
  type: "finish"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesome = _resolveComponent("FontAwesome")!

  return (_openBlock(), _createElementBlock("div", {
    class: "stepper",
    style: _normalizeStyle({ width: typeof _ctx.width === 'number' ? `${_ctx.width}px` : _ctx.width, height: typeof _ctx.height === 'number' ? `${_ctx.height}px` : _ctx.height })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "stepper-progress-percentage",
          style: _normalizeStyle({ width: _ctx.stepperProgress})
        }, null, 4)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepNodes, (node) => {
        return (_openBlock(), _createElementBlock("div", {
          key: node.step,
          class: _normalizeClass(["stepper-node", node.step > _ctx.currentNode.step ? undefined : node.status])
        }, [
          _createElementVNode("div", {
            class: "stepper-counter",
            style: _normalizeStyle({ cursor: _ctx.finished || !_ctx.jumpable || node.step >= _ctx.currentNode.step ? 'not-allowed' : 'pointer' }),
            onClick: ($event: any) => (_ctx.jump(node.step))
          }, [
            (node.status === 'validated')
              ? (_openBlock(), _createBlock(_component_FontAwesome, {
                  key: 0,
                  class: "stepper-icon",
                  icon: "check"
                }))
              : (node.icon)
                ? (_openBlock(), _createBlock(_component_FontAwesome, {
                    key: 1,
                    class: "stepper-icon",
                    icon: node.icon
                  }, null, 8, ["icon"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(node.step), 1))
          ], 12, _hoisted_3),
          _createElementVNode("div", {
            class: "stepper-title",
            innerHTML: node.title
          }, null, 8, _hoisted_5)
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepNodes, (node) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "stepper-tab",
          key: node
        }, [
          (!_ctx.hintHidden && node.hint && node.hintPosition === 'left')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "stepper-hint--left",
                style: _normalizeStyle({ minWidth: typeof node.hintWidth === 'number' ? `${node.hintWidth}px` : node.hintWidth })
              }, [
                _renderSlot(_ctx.$slots, node.hint, {
                  node: node,
                  nodes: _ctx.stepNodes
                })
              ], 4))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            (node.template)
              ? _renderSlot(_ctx.$slots, node.template, {
                  key: 0,
                  node: node,
                  nodes: _ctx.stepNodes
                })
              : (node.content)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    innerHTML: node.content
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true)
          ]),
          (!_ctx.hintHidden && node.hint && node.hintPosition !== 'left')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "stepper-hint--right",
                style: _normalizeStyle({ minWidth: typeof node.hintWidth === 'number' ? `${node.hintWidth}px` : node.hintWidth })
              }, [
                _renderSlot(_ctx.$slots, node.hint, {
                  node: node,
                  nodes: _ctx.stepNodes
                })
              ], 4))
            : _createCommentVNode("", true)
        ])), [
          [_vShow, _ctx.currentNode.step == node.step]
        ])
      }), 128))
    ]),
    (!_ctx.finished)
      ? _renderSlot(_ctx.$slots, "controls", {
          key: 0,
          node: _ctx.node,
          nodes: _ctx.stepNodes
        }, () => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              class: "stepper-button",
              disabled: _ctx.backable && _ctx.currentNode.step == _ctx.stepNodes[0].step,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
            }, [
              _createVNode(_component_FontAwesome, {
                class: "w-2 mr-1",
                icon: "angle-left"
              }),
              _createTextVNode(" 返回 ")
            ], 8, _hoisted_10),
            _createElementVNode("button", {
              class: "stepper-button",
              type: "skip",
              disabled: !_ctx.currentNode.skipable,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.skip()))
            }, [
              _createVNode(_component_FontAwesome, {
                class: "w-2 mr-1",
                icon: "angle-double-right"
              }),
              _createTextVNode(" 跳過 ")
            ], 8, _hoisted_11),
            (_ctx.hasHint)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "stepper-button",
                  type: "hint",
                  disabled: !_ctx.currentNode.hint,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleHint && _ctx.toggleHint(...args)))
                }, [
                  _createVNode(_component_FontAwesome, {
                    class: "w-2 mr-1",
                    icon: "question"
                  }),
                  _createTextVNode(" " + _toDisplayString(`${_ctx.hintHidden? '顯示' : '隱藏'}提示`), 1)
                ], 8, _hoisted_12))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "buttons", {
              node: _ctx.node,
              nodes: _ctx.stepNodes
            }),
            _createElementVNode("button", {
              class: "stepper-button",
              type: "reset",
              disabled: !_ctx.currentNode.reset,
              onClick: _cache[3] || (_cache[3] = () => { if (_ctx.currentNode.reset) _ctx.currentNode.reset(_ctx.currentNode); })
            }, [
              _createVNode(_component_FontAwesome, {
                class: "w-2 mr-1",
                icon: "undo"
              }),
              _createTextVNode(" 重置 ")
            ], 8, _hoisted_13),
            _createElementVNode("button", {
              class: "stepper-button",
              type: _ctx.currentNode.step !== _ctx.stepNodes[_ctx.stepNodes.length - 1].step ? 'continue' : 'complete',
              onClick: _cache[4] || (_cache[4] = () => { _ctx.currentNode.step !== _ctx.stepNodes[_ctx.stepNodes.length - 1].step ? _ctx.next() : _ctx.complete(); })
            }, [
              _createVNode(_component_FontAwesome, {
                class: "w-2 mr-1",
                icon: _ctx.currentNode.step !== _ctx.stepNodes[_ctx.stepNodes.length - 1].step ? 'angle-right' : 'check'
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.currentNode.step !== _ctx.stepNodes[_ctx.stepNodes.length - 1].step ? '繼續' : '完成'), 1)
            ], 8, _hoisted_14)
          ])
        ])
      : _renderSlot(_ctx.$slots, "finish-content", {
          key: 1,
          nodes: _ctx.stepNodes
        }, () => [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("button", _hoisted_16, [
              _createVNode(_component_FontAwesome, {
                class: "w-2 mr-2",
                icon: "award"
              }),
              _createTextVNode(" 已完成所有步驟 "),
              _createVNode(_component_FontAwesome, {
                class: "w-2 ml-2",
                icon: "award"
              })
            ])
          ])
        ])
  ], 4))
}